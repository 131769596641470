export const CONST = {
	_empty:'',
	_slash:'/',
	_space:' ',
	_colon:':',
	active: 'active',
	_images: '_images',
	_videos: '_videos',
	_3d: '_3d',
	_info: '_info',

};
import React, {Component} from 'react';

import "./ContentImage.css";

import i18n from 'i18n';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';

class ContentImage extends Component
{
	constructor(props)
	{
		super(props);
		this.leftClicked = this.leftClicked.bind(this);
		this.rightClicked = this.rightClicked.bind(this);
		this.imageId = 0;
		this.leftRef = React.createRef();
		this.rightRef = React.createRef();
		
	}

	rightClicked()
	{
		this.showImageId(this.imageId+1);
	}

	leftClicked()
	{
		this.showImageId(this.imageId-1);
	}

	showImageId(id)
	{
		if(id<0)
			id=0;
		if(id>this.refs.length-1)
			id = this.refs.length-1;
		
		this.imageId = id;	
		
		for(let i=0; i<this.refs.length;i++)
		{
			if(i == this.imageId)
			{
				this.refs[i].current.classList.add("active");
				this.dotRefs[i].current.classList.add("active");
			}
			else
			{
				this.refs[i].current.classList.remove("active");
				this.dotRefs[i].current.classList.remove("active");
			}
		}

		this.leftRef.current.classList.remove("hidden");
		this.rightRef.current.classList.remove("hidden");
		if(this.imageId<=0)
			this.leftRef.current.classList.add("hidden");
		if(this.imageId>=this.refs.length-1)
			this.rightRef.current.classList.add("hidden");
	}

	componentDidMount()
	{
		this.showImageId(this.imageId);
	}

	componentDidUpdate()
	{
		this.showImageId(this.imageId);
	}

	createImages()
	{
		this.images = [];
		this.refs = [];

		for(let i=0; i<this.props.model.media.length;i++)
		{
			let ref = React.createRef();
			this.refs.push(ref);
			let img = <img key={i} src={"./content/"+this.props.model.media[i]} className={i==0?"active":""} ref={ref} />;
			this.images.push(img);
		}

		return this.images;
	}

	createDots()
	{
		this.dotRefs = [];
		this.dots = [];
		for(let i=0; i<this.props.model.media.length;i++)
		{
			let ref = React.createRef();
			this.dotRefs.push(ref);
			let dot = <div key={i} className={i==0?"active":""} ref={ref}></div>;
			this.dots.push(dot);
		}

		return this.dots;
	}



	render()
	{
		this.imageId = 0;
		return(
			<div className="ContentImage">
				<>{this.createImages()}</>
				<div className={"dots" + (this.props.model.media.length<=1?" hidden":"")}>{this.createDots()}</div>
				<QRCode value={this.props.qrcodeLink} size={100} className="qrcode" bgColor="transparent" />
				<div className="left" onClick={this.leftClicked} ref={this.leftRef}></div>
				<div className="right" onClick={this.rightClicked} ref={this.rightRef}></div>
			</div>			
		);
	}
}

export default ContentImage;
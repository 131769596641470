import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n.use(LanguageDetector).init({
  resources: { 
    en: {
      translations: {
				
				de: "DE",
				en: "EN",
				productSlides:"Product slides"
      }
    },  
    de: {
      translations: {	
				
				de: "DE",
				en: "EN",
				articleNo: "Artikel Nr.:",
				productSlides:"Produkt-Slides",
				modus_photo:"Foto-Ansichtsmodus",
				modus_video:"Video-Ansichtsmodus",
				modus_3d:"3D-Ansichtsmodus",
				modus_info:"Info-Ansichtsmodus",
				not_implemented: "In der Demoversion deaktiviert"
      }
		},
		
   
  },
  fallbackLng: "de",
  debug: process.env.NODE_ENV==="development",

  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    wait: true
  }
});
export default i18n;
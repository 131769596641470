import React, {Component} from 'react';

import "./Header.css";
import i18n from 'i18n';
import { Link } from 'react-router-dom';

class Header extends Component
{
	render()
	{
		return(
			<div className="Header" >
				<div className="logo"></div>
				<span>{i18n.t(this.props.textKey)}</span>	
				<div className="right">
					<Link className="user" to="/" title={i18n.t("not_implemented")}></Link>
					<Link className="search" to="/" title={i18n.t("not_implemented")}></Link>	
					<Link className="menu" to="/" title={i18n.t("not_implemented")}></Link>	
				</div>		
			</div>		
		);
	}
}
export default Header;
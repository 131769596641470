import React, {Component} from 'react';

import "./Content.css";
import i18n from 'i18n';
import { Link, NavLink, Redirect } from 'react-router-dom';
import {GetId, GetSubId} from 'Utils';
import { CONST } from '../../Constants';
import App from 'App';
import ContentInfo from '../ContentInfo/ContentInfo';
import Content3D from '../Content3D/Content3D';
import ContentVideo from '../ContentVideo/ContentVideo';
import ContentImage from '../ContentImage/ContentImage';
import QRCode from 'qrcode.react';

class Content extends Component
{
	constructor(props)
	{
		super(props);
		this.ref = React.createRef();
		this.leftRef = React.createRef();

		this.switchScreen = this.switchScreen.bind(this);
		this.updateFullscreenIcon = this.updateFullscreenIcon.bind(this);

	}

	getTitle()
	{
		const sid = GetSubId();
		
		if(sid === CONST._images)
			return i18n.t("modus_photo");
		else if(sid === CONST._videos)
			return i18n.t("modus_video");
		else if(sid === CONST._3d)
			return i18n.t("modus_3d");
		else if(sid === CONST._info)
			return i18n.t("modus_info");
	}

	getLeftContent()
	{
		if(GetId() === CONST._empty)
			return null;

		const sid = GetSubId();
		if(sid === CONST._images)
			return <ContentImage model={this.getItemSlide("image")} qrcodeLink={this.item.link} ></ContentImage>	
		else if(sid === CONST._videos)
			return <ContentVideo model={this.getItemSlide("video")} qrcodeLink={this.item.link} ></ContentVideo>	
		else if(sid === CONST._3d)
			return <Content3D model={this.getItemSlide("_3d")} ></Content3D>	
		else if(sid === CONST._info)
			return <ContentInfo model={this.getItemSlide("info")} qrcodeLink={this.item.link}></ContentInfo>;		
		return null;
	}

	getItemSlide(_type)
	{
		if(this.item == null || !Array.isArray(this.item.slides))
			return null;

		for(let i=0;i<this.item.slides.length;i++)
			if(this.item.slides[i].type === _type)
				return this.item.slides[i];
		return null;
	}

	getRightContent()
	{
		if(GetId() === CONST._empty)
			return null;

		const sid = GetSubId();
	
		if(sid === CONST._images || sid === CONST._videos || sid === CONST._3d)
		{
			const el = <>
				<div className="head">
					<h1>{this.item.title}</h1>
					<h2>{this.item.subtitle}</h2>
					<h3>{i18n.t("articleNo") +CONST._space+ this.item.article_no}</h3>
					<div className="hr"></div>
					<img src={"./content/"+this.item.ingredient_img_01}></img>
				</div>
				<div className="body">
					{this.item.ingredients.map((d, index) => <p key={index + d.name}><span className="lab" dangerouslySetInnerHTML={{__html:d.name.split("Bio").join("<b>Bio</b>")}}></span><span className="val">{d.value}</span></p>)}
				</div>
				<div className="foot">
					<QRCode value={this.item.link} size={128} className="qrcode hidden" bgColor="transparent" />
					<div className="bg"></div>
					<img src={"./content/"+this.item.ingredient_img_02}></img>
				</div>
			</>;
			return el;
		}
		else if(sid === CONST._info)
		{
			return <><div className="ing_img" style={{backgroundImage:'url(./content/'+this.item.ingredient_img_03+')'}}></div></>;
		}
		return null;
	}

	switchScreen()
	{
		if(this.isFullscreen())
			this.closeFullscreen();
		else
			this.openFullscreen(this.leftRef.current)
	}

	isFullscreen(){ return document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen};
	openFullscreen(elem) {
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) { /* Firefox */
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) { /* IE/Edge */
			elem.msRequestFullscreen();
		}
		
		this.updateFullscreenIcon();
	}

	closeFullscreen() {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) { /* Firefox */
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) { /* IE/Edge */
			document.msExitFullscreen();
		}

		this.updateFullscreenIcon();
	}
	updateFullscreenIcon()
	{
		if(this.isFullscreen())
			this.ref.current.classList.add("fullscreen");
		else
			this.ref.current.classList.remove("fullscreen");

		this.updateSize();
	}
	componentDidMount()
	{
		this.updateSize();
		document.addEventListener("fullscreenchange",	this.updateFullscreenIcon); 
		
		
	}
	componentDidUpdate()
	{
		this.updateSize();
	}

	updateSize()
	{
	//	console.log(16/9, this.leftRef.current.clientWidth/this.leftRef.current.clientHeight)
		if(this.ref.current==null)
			return;
		if(16/9<this.leftRef.current.clientWidth/this.leftRef.current.clientHeight)
		{
			this.ref.current.classList.add("wide");
		}
		else
			this.ref.current.classList.remove("wide");
	}

	render()
	{
		if(GetId() === CONST._empty)
			return(<Redirect to={CONST._slash+0+CONST._slash+CONST._videos}></Redirect>);

		if(GetId() != CONST._empty &&	GetSubId() === CONST._empty)
			return(<Redirect to={CONST._slash+GetId()+CONST._slash+CONST._videos}></Redirect>);

		this.item = App.GetData().items[GetId()];

		if(this.item == null)
			return("");

		return(
			<div className="Content" ref={this.ref} >
				<div className="left" ref={this.leftRef} >
					<div className="stage">{this.getLeftContent()}</div>
					<div className="stageNavigation">
						<div className="nav">
							<NavLink to={CONST._slash+GetId()+CONST._slash+CONST._videos} className={CONST._videos}></NavLink>
							<NavLink to={CONST._slash+GetId()+CONST._slash+CONST._images} className={CONST._images}></NavLink>
							<NavLink to={CONST._slash+GetId()+CONST._slash+CONST._3d} className={CONST._3d}></NavLink>
							<NavLink to={CONST._slash+GetId()+CONST._slash+CONST._info} className={CONST._info}></NavLink>								
						</div>
						<span className="title">
							{this.getTitle()}
						</span>
						<div className="fullscreen" onClick={this.switchScreen}></div>						
					</div>
				</div>
				<div className="right">{this.getRightContent()}</div>
			</div>		
		);
	}
}
export default Content;
import React, {Component} from 'react';

import "./Footer.css";
import i18n from 'i18n';
import { Link, NavLink } from 'react-router-dom';
import App from 'App';
import { CONST } from '../../Constants';
class Footer extends Component
{
	render()
	{
		

		const items = App.GetData().items;
		let i=0;
		const itemsElements = 	App.GetData().items.map((d, index) => <NavLink key={index + d.name} to={CONST._slash+index}><span>{d.name}</span><div className="close" title={i18n.t("not_implemented")}></div></NavLink>);
		return(
			<div className="Footer" >
				<span>{i18n.t("productSlides")}</span>
				<div className="products">
					{
					itemsElements
					}
				</div>
			</div>		
		);
	}
}
export default Footer;
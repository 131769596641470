import React, {Component} from 'react';

import "./ContentInfo.css";

import i18n from 'i18n';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';

class ContentInfo extends Component
{
	render()
	{
		return(
			<div className="ContentInfo">
				<h1>{this.props.model.title}</h1>
				<h2>{Array.isArray(this.props.model.subtitle)?this.props.model.subtitle.join("\r\n"):this.props.model.subtitle}</h2>
				<QRCode value={this.props.qrcodeLink} size={100} className="qrcode" bgColor="transparent" />
				<div className="hr"></div>
				<div className="list">
					{this.props.model.pros.map((d, index) => <div key={index + d}>{d}</div>)}
				</div>
				<div className="table" dangerouslySetInnerHTML={{__html:this.props.model.table}}></div>
			</div>
			
		);
	}
}

export default ContentInfo;
import React, {Component} from 'react';

import "./ContentVideo.css";

import i18n from 'i18n';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';

class ContentVideo extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {paused:false}
		this.videoRef = React.createRef();
		this.playClicked = this.playClicked.bind(this);
	}

	playClicked()
	{
		if(!this.state.paused)
			this.setState({paused:true})
		else
			this.setState({paused:false})
	}


	componentDidMount()
	{
		this.videoRef.current.addEventListener(
			'ended',
			function myHandler(e) {this.setState({paused:true})}.bind(this),
			false);    
	}
	componentDidUpdate()
	{
		if(this.state.paused)
			this.videoRef.current.pause()
		else
			this.videoRef.current.play()
	}
	render()
	{
		console.log(this.state)
		return(
			<div className="ContentVideo">
				<video autoPlay muted ref={this.videoRef} >
					<source src={"./content/"+this.props.model.media[0]} type="video/mp4" />
				</video>
				<div className={"ctrl_btn" + (this.state.paused?"":" pause")}onClick={this.playClicked}></div>
				<QRCode value={this.props.qrcodeLink} size={100} className="qrcode" bgColor="transparent" />
			</div>			
		);
	}
}

export default ContentVideo;
import React, {Component} from 'react';

import "./Content3D.css";

import i18n from 'i18n';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';

class Content3D extends Component
{
	render()
	{
		return(
			<div className="Content3D">
				<iframe src={this.props.model.media[0]}></iframe>
				<QRCode value={this.props.model.media[0]} size={100} className="qrcode" bgColor="transparent" />
			</div>			
		);
	}
}

export default Content3D;

import React, { Component, Suspense } from 'react';
import {Redirect, withRouter} from 'react-router-dom';

import './App.css';


import {LanguageManager} from 'Utils';
import { CONST } from './Constants';
import i18n from './i18n';

import i18next from 'i18next';
import Header from './ui/Header/Header';
import Footer from './ui/Footer/Footer';
import Content from './ui/Content/Content';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	static GetData()
	{
		return App.data;
	}
	componentDidMount()
	{
		
		fetch('content/data.json').then((r) => {return r.json();})
		.then((data) =>{			
			App.data = data;			
			this.setState({dataloaded:true, data:data});
		});
	}
	
  render() {
		
		if(!this.state.dataloaded)
			return("Loading...");
		
		document.documentElement.lang = "de";		
		return (
      <div className="App">
				<Suspense fallback={<div>Loading...</div>}>
					<Header></Header>
					<Content></Content>
					<Footer></Footer>
				</Suspense>				
		  </div>		
    );
  }
}

export default withRouter(App);
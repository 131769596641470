import {CONST} from 'Constants';
import i18n from 'i18n';
import i18next from 'i18next';

export function ValidateEmail(email)
{
	// eslint-disable-next-line
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function IsMobile()
{
	let c = document.getElementsByClassName("App");
	if(c.length>0 && c[0].classList!=null && c[0].classList.contains("mobile"))
		return true;
	return false;
}

export function ConvertBBToHTMl(str)
{
	str = str.replace("[i]", "<i>");
	str = str.replace("[/i]", "</i>");
	str = str.replace("[b]", "<b>");
	str = str.replace("[/b]", "</b>");
	str = str.replace("#b#", "<b>");
	str = str.replace("#/b#", "</b>");
	str = str.replace("<b></b><br/>", "");
	str = str.replace("<b>  </b><br/>", "");
	return str;
}

export function ConvertLineBreaks(str)
{
	if(str == null)
		return "";


	if(str == parseFloat(str))
		str = str+"";
	str = str.split("\r\n").join("<br/>"); 
	str = str.split("\n").join("<br/>"); 
	str = str.split("\r").join("<br/>");

		
	return str;
}

export function ConvertNumber(str)
{
	if(str == null)
		return "";
	
	if(str == parseFloat(str))
		str = str+"";
	str = str.replace("[i]", "<i>");
	str = str.replace("[/i]", "</i>");
	var res = str.replace(/<number>(.*?)<\/number>/g, function (x) {
		x = x.replace("<number>", "<span class='nr'>");
		x = x.replace("</number>", "</span>");
		x = x.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');  // &thinsp;
	return x;
	}); 
	return res;
}

export function FormatSecondsToTime(seconds)
{
	seconds = parseInt(seconds+"");
	let _hours = parseInt(seconds/3600);
	let _minutes = parseInt(seconds/60);
	let _seconds = seconds%60;
	
	if(_hours>0)
		return pad(_hours, 2)+":"+pad(_minutes,2)+":"+pad(_seconds,2);
	return pad(_minutes,2)+":"+pad(_seconds,2);//"00:00";
}
export function ConvertMySqlDateForDisplay(dateString)
{
	var dateTime = dateString.split(' ');
	var date = dateTime[0].split('-');
	var time = dateTime[1].split(':');
	var utc = Date.UTC(date[0], date[1]-1, date[2], time[0], time[1], time[2]);
	let _utcdate = new Date(utc);   

	
	return _utcdate.getDate() 
    + '.' + pad(_utcdate.getMonth()+1)
    + '.' + pad(_utcdate.getFullYear())
    + ' - ' + pad(_utcdate.getHours())
    + ':' + pad(_utcdate.getMinutes())+"h";
}

export function pad(padString, length) {
	if(length==null)
		length=2;
	var str = padString+"";
	while (str.length < length)
			str = "0" + str;
	return str;
}

//#region hashstring
function djb2(str){
  var hash = 5381;
  for (var i = 0; i < str.length; i++) {
    hash = ((hash << 5) + hash) + str.charCodeAt(i); /* hash * 33 + c */
  }
  return hash;
}

 
export function HashStringToColor(str) {
	if(str ==null)
		str="anonymous";
  var hash = djb2(str);
  var r = (hash & 0xFF0000) >> 16;
  var g = (hash & 0x00FF00) >> 8;
  var b = hash & 0x0000FF;
  return "#" + ("0" + r.toString(16)).substr(-2) + ("0" + g.toString(16)).substr(-2) + ("0" + b.toString(16)).substr(-2);
}
//#endregion hashstring


export function GetArgs()
{
	let parts = document.location.hash.split("#/");
	if(parts.length<=1)
		return "";
	if(parts.length>1)
	{
		let _parts = parts[1].split("/");
		return _parts;		
	}	
}

export function GetId()
{
	let args = GetArgs();
	if(args.length>0)
		return args[0];

	return "";
}

export function GetSubId()
{
	let args = GetArgs();
	if(args.length>1)
		return args[1];
		
	return "";
}